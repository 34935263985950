<template>
	<div class="menu-wrapper search-slider">
		<div class="search-bar" v-on:click="toggleSearchSlide()">
			<div class="text">Where to next?</div>
			<img src="@/assets/home/icons/search-icon.png" alt="search" />
		</div>
		<div class="menu-slideout" :class="{'open' : isSlideActive}">
			<div class="back-button" v-on:click="toggleSearchSlide()" >
				<img src="@/assets/menu/arrow-left.png"/>
			</div>
			<div class="menu-panel">
				<h4 class="search-text">Where to?</h4>
				<SearchInput ref="countrySearch"
					@click.native="showSearchOptions = false"
					@input="handleOption"
				/>
				<div v-if="showSearchOptions">
					<div class="add-more">
						<div class="text">What are you looking for?</div>
						<div class="add-more-container">
							<div class="item-wrapper has-text" @click="flightSearch">
								<div class="item">
									<img src="@/assets/trip/flight-large.png" alt="Flight Icon" width="36px"/>
								</div>
								<div class="item-text">
									Flights
								</div>
							</div>
							<div class="item-wrapper has-text" @click="tourSearch">
								<div class="item">
									<img src="@/assets/trip/tour-large.png" alt="Tour Icon" width="36px"/>
								</div>
								<div class="item-text">
									Tours
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="background-overlay" v-on:click="toggleSearchSlide()" :class="{'open' : isSlideActive}"></div>
	</div>
</template>

<script>
	import SearchInput from '@/components/SearchInput.vue'
	import router from '@/router';
	import { mapActions } from 'vuex'

	export default {
    name: 'SearchSlideout',
		data() {
			return {
				isSlideActive: false,

				showSearchOptions: false,
				selectedInput: null,
			}
		},
		computed: {
		},
		methods: {
			...mapActions({
				setFlightDestination: "flightsSearch/setDestination",
				setTourKeyword: "toursSearch/setKeyword",
			}),
			toggleSearchSlide() {
				this.isSlideActive = !this.isSlideActive;
				this.$refs.countrySearch.focus();
     		},
			handleOption(input) {
				this.selectedInput = input;
				
				if(input.location_type == 'city') {
					// Show options for flight or tour search
					this.showSearchOptions = true;
					return
				} else {
					this.showSearchOptions = false;
				}

				if(input.location_type == 'airport') {
					this.flightSearch();
				} else if(input.location_type == 'country') {
					this.goToCountry();
				} 
				return
			},
			flightSearch() {
				this.setFlightDestination(this.selectedInput);

				router.push({
					name: "Flights Locations",
				});
			},
			tourSearch() {
				this.setTourKeyword(this.selectedInput);

				router.push({
					name: "Tours Dates",
				});
			},
			goToCountry() {
				let countryCode = this.selectedInput.code;
				router.push({
					name: 'Country',
					params: { 
						country_code: countryCode,
					}
				});
			}
		},
		components: {
			SearchInput,
		}
	}
</script>

<style>
	.search-bar {
		width: 300px;
		height: 48px;
		border: solid 1px #E5E5E5;
		border-radius: 50px;
		background: #FBFBFB;
		margin: auto;
		text-align: initial;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
	}
	.search-bar .text {
		width: 75%;
		color: black;
		border: none;
		background: transparent;
		font-size: 14px;
		line-height: 24px;
	}
	.search-bar img {
		width: 24px;
		height: 24px;
		cursor: pointer;
	}
	.search-bar input:focus {
		outline: none;
	}

	.search-slider .menu-panel {
		padding-top: 15px;
		padding-left: 22px;
		text-align: left;
	}
	.search-slider .search-text {
		margin-bottom: 5px;
		color: black;
	}
	.add-more {
		margin-top: 35px;
	}
</style>